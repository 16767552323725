<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <section class="question-section section-gap-30">
                <div class="back-button">
                    <router-link :to="{name: 'company.question.group'}" class="back-button__icon">
                        <i class="eicon e-back"></i>
                    </router-link>
                    <div class="back-button__text text-capitalize">{{ $t("back to all question set")}}</div>
                </div>
                <div class="section-title mb-4 text-capitalize" v-if="!editMode">
                    {{ $t("create question set")}}
                    <v-popover offset="10" style="display: initial;">
                        <button class="ej__tooltip__button mlF-1"><i class="eicon e-question"></i></button>
                        <template slot="popover">
                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                            <a v-close-popover href="https://easy.jobs/docs/create-question-set" target="_blank">
                                {{ $t(`How to create a question set ?`) }}
                            </a>
                        </template>
                    </v-popover>
                </div>
                <div class="section-title mb-4 text-capitalize" v-if="editMode">{{ $t("edit question set")}}
                    <v-popover offset="10" style="display: initial;">
                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                        <template slot="popover">
                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                            <a v-close-popover href="https://easy.jobs/docs/create-question-set" target="_blank">
                                {{ $t(`How to create a question set ?`) }}
                            </a>
                        </template>
                    </v-popover>
                </div>
                <form action="">
                    <div class="form-box">
                        <div class="form-group row">
                            <div class="col-md-9">
                                <label class="text-capitalize">{{ $t("set name")}}*</label>
                                <input type="text" id="set_name" ref="set_name" v-model="form.set_name" maxlength="250"  class="form-control" :placeholder="$t('Question Set Name')" required autofocus>
                                <error-message :message="$t(errors.set_name)" />
                            </div>
                            <div class="col-md-3">
                                <label class="text-capitalize">{{ $t("Set Type")}}*</label>
                                <multiselect
                                        id="set_type"
                                        v-model="form.set_type"
                                        :options="setTypeOptions"
                                        :placeholder="$t('Select Type')"
                                        track-by="id"
                                        label="name"
                                        :allow-empty="false"
                                        deselect-label=""
                                        select-label=""
                                        @input="changeSetType($event)"
                                ></multiselect>
                                <error-message :message="$t(errors.set_type)" />
                            </div>
                        </div>
                    </div>

                    <template v-if="!isQuiz">
                        <screening-question
                            v-for="(question, index) in form.questions"
                            :key="index"
                            :question="question"
                            :errors="errors"
                            :questionTypeOptions="questionTypeOptions"
                            :total-question="form.questions.length"
                            @moveUp="moveUp($event)"
                            @moveDown="moveDown($event)"
                            @deleteQuestion="deleteQuestion($event)"
                        >
                        </screening-question>
                    </template>

                    <template v-if="isQuiz">
                        <quiz-question
                            v-for="(question, index) in form.questions"
                            :key="index"
                            :question="question"
                            :errors="errors"
                            :total-question="form.questions.length"
                            @moveUp="moveUp($event)"
                            @moveDown="moveDown($event)"
                            @deleteQuestion="deleteQuestion($event)"
                        >
                        </quiz-question>
                    </template>

                    <div class="mt-30">
                        <button class="button primary-button text-capitalize" @click.prevent="addQuestion">
                            <span class="icon"><i class="eicon e-plus"></i></span>
                            <span class="text-capitalize">{{ $t("add question")}}</span>
                        </button>
                    </div>
                    <div class="section-gap d-flex">
                        <submit-button :click="saveQuestionSet" v-if="!editMode" class="info-button ml-auto text-capitalize" :disabled="saveButton.disabled" :loading="isLoading">{{ $t("save question set")}}</submit-button>
                        <submit-button :click="updateQuestionSet" v-if="editMode" class="info-button ml-auto text-capitalize" :disabled="saveButton.disabled" :loading="isLoading">{{ $t("update question set")}}</submit-button>
                    </div>
                </form>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import client from '../../app/api/Client';
import Multiselect from 'vue-multiselect';
import {JOB} from '../../extra/constants';
const ErrorMessage = () => import("../../components/common/ErrorMessage.vue");
const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
import evalution from "../../services/validations/evalution"
import QuizQuestion from "../../components/questions/QuizQuestion";
import ScreeningQuestion from "../../components/questions/ScreeningQuestion";

export default {
    components: {
        ErrorMessage,
        DashboardLayout,
        Multiselect,
        QuizQuestion,
        ScreeningQuestion
    },
    data() {
        return {
            form: {
                id: null,
                set_type: '',
                set_name: '',
                questions: []
            },
            isQuiz: false,
            setTypeOptions: [],
            questionTypeOptions: [],
            hasErrorInQuestions: false,
            errors: [],
            saveButton: {
                disabled: false,
            },
            isLoading: false,
            titleError: []
        }
    },

    computed: {
        editMode() {
            return !!this.$route.params.id;
        }
    },

    methods: {
        moveUp(index, elem) {
            [this.form.questions[index], this.form.questions[index - 1]] = [this.form.questions[index - 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },

        moveDown(index, $event) {
            [this.form.questions[index], this.form.questions[index + 1]] = [this.form.questions[index + 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },

        changeSetType(event) {
            this.isQuiz = (event.id === JOB.EXAM_TYPES.QUIZ);
            this.titleError = [];
            this.form.questions.map(q => {
                q.errors = [];
                if (q.isMultiple) {
                    let isAnsArr = _.isArray(q.answers);
                    if (this.isQuiz) {
                        if (isAnsArr && q.answers.length > 0) {
                            q.answers = _.first(q.answers);
                        }
                    } else {
                        if (!isAnsArr) {
                            q.answers = [q.answers];
                        }
                    }
                }
                return q;
            });
        },

        validationErrorFocus() {
            let focusInputs = ['set_name'];
            let focusKey = Object.keys(this.errors)[0];
            if(focusInputs.includes(focusKey)) {
                window.scrollTo(0,0);
                this.$refs[focusKey].focus();
            }
        },

        async saveQuestionSet() {

            this.validateQuestionSet();

            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            if (this.form.questions.length === 0) {
                this.$toast.error(this.$t('Question not added.'));
                return false;
            } else {
                this.validateQuestions();

                if (this.hasErrorInQuestions) {
                    return false;
                }
            }

            this.isLoading = true;
            try {
                let {data} = await client().post(`/company/question/group/create`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                    await this.$router.push({name: 'company.question.group'});
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },

        async updateQuestionSet() {

            this.validateQuestionSet();


            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            if (!this.form.id) {
                this.$toast.error(this.$t('Invalid id'));
                return false;
            }

            if (this.form.questions.length === 0) {
                this.$toast.error(this.$t('Question not added.'));
                return false;
            } else {
                this.validateQuestions();

                if (this.hasErrorInQuestions) {
                    return false;
                }
            }

            this.isLoading = true;
            try {
                let {data} = await client().post(`/company/question/group/${this.form.id}/update`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                    await this.$router.push({name: 'company.question.group'});
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },

        addQuestion() {
            this.validateQuestionSet();

            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            this.validateQuestions();

            if (this.hasErrorInQuestions) {
                return false;
            }

            this.form.questions.push({
                id: null,
                type: '',
                title: "",
                options: [
                    {
                        id: null,
                        title: '',
                    },
                    {
                        id: null,
                        title: '',
                    }
                ],
                answers: [],
                isValid: false,
                errors: [],
                isMultiple: false
            });
        },

        deleteQuestion(key) {
            let message = {
                title: this.$t("Confirmation"),
                body: this.$t("are you sure you wanted to delete this question?")
            };

            this.$dialog.confirm(message).then(() => {
                this.form.questions.splice(key, 1);
            });
        },

        validateQuestionSet() {
            let { errors, hasErrorInQuestions } = evalution.validateQuestionSet(this.form);
            this.errors = errors;
            this.hasErrorInQuestions = hasErrorInQuestions;
        },

        validateQuestions() {
            let {hasErrorInQuestions} = evalution.validateQuestions(this.form.questions, this.isQuiz);
            this.hasErrorInQuestions = hasErrorInQuestions;
        },

        async loadEditData() {
            if (this.editMode) {
                this.form.id = this.$route.params.id;
                await this.loadQuestionSetData();
            }
        },

        async loadQuestionMetaData() {
            let {data: {data}} = await client().get('/question/meta-data');
            this.setTypeOptions = data.set_types;
            this.questionTypeOptions = data.question_types;
        },

        async loadQuestionSetData() {
            let {data: {data}} = await client().get(`/company/question/group/${this.$route.params.id}`);
            this.form = data;
            this.isQuiz = data.set_type.id === JOB.EXAM_TYPES.QUIZ;
        },
    },
    async created() {
        await this.loadEditData();

        await this.loadQuestionMetaData();
    }
}
</script>
